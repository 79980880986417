.loading-panel {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.9;
    background-image: url($base-url + 'images/loader.gif');
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 7;
}

.mat-dialog-container .loading-panel {
    position: absolute;
}
@include media-breakpoint-down(md) {
    
}

.dialog-tyre-results .mat-dialog-content {
    // max-height: initial !important;
    // max-height: 80vh;
}

@include media-breakpoint-down(md) { 
    .loading-panel {
        position: fixed;
    }
    
    .dialog-tyre-results .mat-dialog-content {
        // max-height: initial !important;
        // max-height: 65vh;
    }  
}