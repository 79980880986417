.btn-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle; // match .btn alignment given font-size hack above

	>.btn {
		position: relative;
		flex: 1 1 auto;

		// Bring the hover, focused, and "active" buttons to the front to overlay
		// the borders properly
		@include hover() {
			z-index: 1;
		}

		&:focus,
		&:active,
		&.active {
			z-index: 1;
		}
	}

	// Prevent double borders when buttons are next to each other
	>.btn:not(:first-child),
	>.btn-group:not(:first-child) {
		margin-left: -$btn-border-width;
	}

	// Reset rounded corners
	>.btn:not(:last-child):not(.dropdown-toggle),
	>.btn-group:not(:last-child)>.btn {
		@include border-right-radius(0);
	}

	>.btn:not(:first-child),
	>.btn-group:not(:first-child)>.btn {
		@include border-left-radius(0);
	}
}
