.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-size: $font-size-base;
    font-weight: $font-weight-bold;
	color: $body-color;
	text-align: center;
	white-space: $btn-white-space;
	vertical-align: middle;
	cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
	@include transition($btn-transition);

	@include hover() {
		color: $body-color;
		text-decoration: none;
	}

	&:focus,
	&.focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
		cursor: default;
		@include box-shadow(none);
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}

.btn-primary {
	@include button-variant($primary, $primary);
}

.btn-success {
	@include button-variant($success, $success);
}

.btn-light {
	@include button-variant($light, $light);
}

.btn-outline-primary {
	@include button-outline-variant($primary);
}

.btn-link {
	font-weight: $font-weight-normal;
	color: $link-color;
	text-decoration: $link-decoration;

	@include hover() {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus,
	&.focus {
		text-decoration: $link-hover-decoration;
		box-shadow: none;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
		pointer-events: none;
	}
}

.btn-lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

.btn-block {
	display: block;
	width: 100%;

	+.btn-block {
		margin-top: $btn-block-spacing-y;
	}
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}

.btn-loading-img {
    position: relative;
    width: 15px;
    height: 15px;
    margin: -6px 0.25rem -4px 0;
}

.btn-lg {
    .btn-loading-img {
        position: relative;
        width: 18px;
        height: 18px;
        margin: -8px 0.25rem -4px 0;
    }
}