.custom-scrollbar {
    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }
 
    /* Track */
    &::-webkit-scrollbar-track {
        background: $gray-100;
        border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $gray-400;
        border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: darken($gray-400, 5%);
    }
}
 
.custom-backdrop {
    background-color: rgba(255,255,255,0.8);
}