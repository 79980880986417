*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}


hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: $dt-font-weight;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0; // Undo browser default
}

b,
strong {
  font-weight: $font-weight-bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

small {
  @include font-size(80%); // Add the correct font size in all browsers
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  @include hover() {
    color: inherit;
    text-decoration: none;
  }
}


img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
}

svg {
  // Workaround for the SVG overflow bug in IE10/11 is still required.
  // See https://github.com/twbs/bootstrap/issues/26878
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse; // Prevent double borders
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: $label-margin-bottom;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  @include font-size(inherit);
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}


button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
}

@if $enable-pointer-cursor-for-buttons {
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    &:not(:disabled) {
      cursor: pointer;
    }
  }
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}


input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[hidden] {
  display: none !important;
}
