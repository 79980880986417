// This is some basic theming that MUST use CSS variables instead of CSS variables, because
// we can change the CSS var at run-time, so we can change the branding colour on the app init
// once we have a value from the server.

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    &:hover,
    &:disabled,
    &.disabled,
    &.active,
    &:active {
        background-color: var(--primary-hover) !important;
        border-color: var(--primary-hover) !important;
    }
    &:focus,
    &.focus { 
        background-color: var(--primary-hover) !important;
        border-color: var(--primary) !important;
        box-shadow: none !important;
    }
}

.btn-outline-primary {
    color: var(--primary)!important;
    border-color: var(--primary)!important;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:hover,
    &:disabled,
    &.disabled {
        background-color: var(--primary)!important;
        border-color: var(--primary)!important;
        color:white !important;
    }
}

.mat-calendar-body-selected {
    background-color: var(--primary)!important;
}