@use '@angular/material' as mat;
@import "@angular/material/theming";

@import "./bootstrap/functions";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";
@import "app-vars";

@include mat.core();
$bookingSystem-primary: mat.define-palette(mat.$blue-palette);
$bookingSystem-accent: mat.define-palette(mat.$blue-palette, A400, A200, A700);
$bookingSystem-warn: mat.define-palette(mat.$red-palette);
$bookingSystem-theme: mat.define-light-theme($bookingSystem-primary, $bookingSystem-accent, $bookingSystem-warn);
@include mat.all-component-themes($bookingSystem-theme);

@import "root";
@import "material";
@import "font-awesome";

.mst {
    margin: 0; // 1
    font-family: $font-family-base;
    // @include font-size($font-size-base);
    font-size: 16px;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    -webkit-text-size-adjust: 100%; // 4
    -webkit-tap-highlight-color: rgba($black, 0); // 5
    color: $body-color;
    text-align: left; // 3
}

.mst {
    @import "reboot";
    @import "type";
    @import "buttons";
    @import "grid";
    @import "button-group";
    @import "alert";
    @import "loading-panel";
    @import "scrollable"; 
    @import "utilities";
    @import "modal";
    @import "carousel";
    @import "theme";
}

.btn-close-dialog {
    padding:0px;
    border:0px;
    display: inline-block;
    background-color: transparent;
    outline: none !important;
    &:focus, &:hover {
        outline:none !important;
    }
    // position: absolute;
    // top: map-get($spacers, 4);
    // right: map-get($spacers, 4);
    i {
        color: $gray-500;
        &:hover {
            color: $gray-600;
        }
    }
}

body {
    margin: 0;
    font-family: proxima-nova, "Helvetica Neue", sans-serif;
}

@media only screen and (max-width: 376px) {
    .mst {
        font-size: 14px;
    }
}

@include media-breakpoint-up(lg) { 
    .layout-main,
    .vehicle-feed-main {
        max-height: initial !important;
    }
}

@include media-breakpoint-down(md) { 
    // the below code may need changing if we ever move to direct embeds on the page etc... 
    html.no-iframe, html.no-iframe body {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        app-root, app-layout-main {
            flex: 1;
            display: flex;
            max-width: 100%;
            width: 100%;
            min-width: 0;
        }
    }

    html.car-dealer-app, html.car-dealer-app body {
        overflow:initial !important;
        display: block !important;
        height: auto !important;
    }

    html.no-iframe .layout-main {
        min-height: initial !important;
    }

    html.iframe:not(.widget-fallback), html.iframe:not(.widget-fallback) body {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        app-root, app-layout-main {
            flex: 1;
            display: flex;
            flex: 1;
            display: flex;
            max-width: 100%;
            width: 100%;
            min-width: 0;
        }
    }
    html.iframe .layout-main {
        min-height: initial !important;
    }
}

.widget-fallbacks {
    display: none;
    width: 100%;
}
@include media-breakpoint-down(md) { 
    .full-width-modal .modal-dialog {
        margin:3px;
        max-width: calc(100% - 6px);
        max-height: calc(100% - 6px);
    }
    .full-width-modal .modal-body {
        padding: 0px;
    }
}


@include media-breakpoint-down(md) {
    html.iframe.widget-fallback .loading-panel {
        display: none;
    }
    html.iframe.widget-fallback .widget-fallbacks {
        display: block;
    }
    html.iframe.widget-fallback .layout-wrapper {
        display: none !important;
    }
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// Resize mat select panel when falling back within an iframe.
// Stops the dropdown getting cutoff.

.iframe.widget-fallback.widget-fallback-tyre .widget_manual-entry {
    min-height: 225px;
    
}
.iframe.widget-fallback.widget-fallback-tyre .mat-select-panel {
    max-height: 155px !important; 
}

.mat-placeholder-required.mat-form-field-required-marker {
	color: #f44336;
}
