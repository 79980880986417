.mat-dialog-container {
    position: relative;
}

.mat-form-field {
    font-size: $font-size-sm;
}

label.mat-checkbox-layout {
    display: inline-flex;
}